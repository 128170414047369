<template>
    <div class="template-select">
      <online-wa-template-picker-modal
        v-model="templateToSend"
        :inbox-id="inboxId"
        :show="showTemplatePicker"
        @close="showTemplatePicker = false"
        @cancel="showTemplatePicker = false"
      />
        <template v-if="useTemplateSelection">
            <woot-loading-state style="padding: var(--space-smaller)" v-if="templateUiFlags.isFetching"/>
            <div v-else>
              <woot-button
                           name="template-button"
                           variant="clear"
                           color-scheme="info"
                           icon="brand-whatsapp"
                           @click.prevent="showTemplatePicker = !showTemplatePicker"
              >
                {{ templateToSend || $t('WHATSAPP_TEMPLATES.MODAL.SUBTITLE') }}
              </woot-button>
<!--                <select v-model="templateToSend">-->
<!--                    <option v-for="tmpl in templates" :key="tmpl.name" :value="tmpl.name">-->
<!--                        {{ tmpl.label || tmpl.text }}-->
<!--                    </option>-->
<!--                </select>-->
            </div>
        </template>
        <template v-else>
            <textarea
                    class="resize-none border w-full py-2 px-3 text-slate-700 leading-tight outline-none"
                    v-model="dirtyText"
                    :placeholder="$t('CAMPAIGN.ADD.FORM.MESSAGE.PLACEHOLDER')"
            />
        </template>
          <div v-if="templateVariablesArray" class="example-wrapper">
              <span v-if="withBodyVariables" style="font-size: small">{{ $t('CONVERSATION.REPLYBOX.TEMPLATE_PARAMETERS_EXAMPLE') }}:</span>
              <div>
                <strong v-if="withBodyVariables" class="text-block" style="margin-inline-start: 1rem" >{{this.message}}</strong>
                <div v-if="withWebsiteVariable" style="margin-top: 0.5rem">
                    {{ $t('CONVERSATION.REPLYBOX.TEMPLATE_PARAMETERS_WEBSITE') }}:
                    <strong>
                      {{this.templateVariablesArray[0] && this.templateVariablesArray[0].websiteVariable || '???'}}
                    </strong>
                </div>
                <div v-if="withWebsiteVariable2" style="margin-top: 0.5rem">
                    {{ $t('CONVERSATION.REPLYBOX.TEMPLATE_PARAMETERS_WEBSITE') }} #2:
                    <strong>
                      {{this.templateVariablesArray[0] && this.templateVariablesArray[0].websiteVariable2 || '???'}}
                    </strong>
                </div>
              </div>
          </div>
          <div v-else>
            <div v-if="withBodyVariables">
                <label >
                    {{ $t('CONVERSATION.REPLYBOX.FILL_IN_PARAMETERS') }}:
                </label>
                <template v-for="(item, pos) in tokenisedTemplate">
                    <template v-if="item.type === 'parameter'" >
                        <input v-model="templateVariables[item.value]"
                               oninput="this.style.width = (this.value.length + 1) + 'ch';" :key="`${item.name}-${pos}`"
                               type="text" :name="`${item.name}-${item.value}`" class="template-input" />
                        <!--              for not hebrew in hebrew locale  -->
                        <i style="opacity: 0; margin-inline-start: -1ch;">i</i>
                    </template>
                    <span class="text-block" v-else>{{item.text}}</span>
                </template>
            </div>
            <div v-if="withWebsiteVariable" class="website-input--wrapper">
                <span style="white-space: nowrap">{{ $t('CONVERSATION.REPLYBOX.TEMPLATE_PARAMETERS_WEBSITE') }}:</span>
                <input v-model="websiteVariable" @input="onTemplateChange" type="text"/>
            </div>
              <div v-if="withWebsiteVariable2" class="website-input--wrapper">
                <span style="white-space: nowrap">{{ $t('CONVERSATION.REPLYBOX.TEMPLATE_PARAMETERS_WEBSITE') }} #2:</span>
                <input v-model="withWebsiteVariable2" @input="onTemplateChange" type="text"/>
            </div>
          </div>
        <div v-if="showFileUpload && allowedAttachments" class="attachment-wrapper">
          <file-upload
                  name="templates-file-upload"
                  ref="upload-new_-conv"
                  :size="4096 * 4096"
                  :accept="allowedAttachments"
                  :drop="false"
                  :drop-directory="false"
                  @input-file="onFileUpload"
          >
              <woot-button
                      class-names="button--upload"
                      :title="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
                      icon="ion-android-attach"
                      emoji="📎"
                      color-scheme="secondary"
                      variant="smooth"
                      size="small"
              />
          </file-upload>
          <bafi-file-attacher style="margin-inline-start: 1rem" :on-file-download="onFileUpload"/>
          <div v-if="hasAttachments || fileUploadProgresses.length" class="attachment-preview-box">
              <attachments-preview
                      :progresses="fileUploadProgresses"
                      :attachments="attachedFiles"
                      :remove-attachment="removeAttachment"
              />
          </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import WhatsappTemplateTokenizer from "./WhatsappTemplateTokenizer";
import WootButton from "../../../ui/WootButton";
import FileUpload from "vue-upload-component";
import {checkFileSizeLimit} from "../../../../../shared/helpers/FileHelper";
import AttachmentsPreview from "../../AttachmentsPreview";
import TextArea from "../../../../../widget/components/Form/TextArea";
import {DirectUpload} from "activestorage";
import alertMixin from 'shared/mixins/alertMixin';
import BafiFileAttacher from "../../online99_platform/BafiFileAttacher";
import OnlineWaTemplatePickerModal from "./OnlineWaTemplatePickerModal.vue";
import { allowedSize } from '../../../../helper/fileAttached';
import { waMaxSizeText } from '../../../../helper/maxSizes';

export default {
  name: 'OnlineWaTemplateSelector',
  components: {OnlineWaTemplatePickerModal, BafiFileAttacher, TextArea, AttachmentsPreview, FileUpload, WootButton},
  mixins: [alertMixin],
  props: {
    inboxId: {
      type: [String, Number],
      required: true,
    },
    conversationId: {
      type: [String, Number],
      required: false,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    clear: {
      type: Boolean
    },
    showFileUpload: {
      type: Boolean,
      default: false,
    },
    templateVariablesArray: {
      type: Array,
      default: null
    },
    templateText: {
      type: String,
      default: null
    },
    useTemplateSelection: {
      type: Boolean,
      default: true
    },
    fileUrl: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      templateToSend: '',
      tokenisedTemplate: [],
      templateVariables: {},
      lexer: new WhatsappTemplateTokenizer(),
      attachedFiles: [],
      dirtyText: undefined,
      websiteVariable: undefined,
      fileUploadProgresses: [],
      showTemplatePicker: false,
    };
  },
  watch: {
    inboxId() { //not force updating
      this.clearUserSelection();
      if (this.templates.length === 0) {
        this.init();
      }
      if (this.useTemplateSelection) {
        //new
        if (this.inboxId && !this.templateToSend) {
          this.showTemplatePicker = true
        }
      }
    },
    clear() {
      if (this.clear) {
        this.clearUserSelection()
      }
    },
    templateToSend() {
      if(!this.templateVariablesArray) {
        //to reset changed widths to initial values
        this.createTokenisedTemplate('') //todo
        this.$nextTick(() => {
          this.createTokenisedTemplate(this.currentTemplate.text)
        });
      } else {
        this.createTokenisedTemplate(this.currentTemplate.text)
      }

      //todo more nice (remove duplicating)
      if(this.fileUrl && this.templateText && this.isTextEqual(this.currentTemplate.text, this.templateText)){
        this.initAttachment()
      } else {
        this.attachedFiles = [];
      }
      //this.onTemplateChange()
      this.websiteVariable = undefined
    },
    templateVariablesArray() {
      // if (this.templateVariablesArray && this.templateVariablesArray.length > 0) {
      //   this.templateVariables = this.templateVariablesArray[0]
      // }
      if (this.currentTemplate.text) {
        this.createTokenisedTemplate(this.currentTemplate.text)
      } else {
        this.createTokenisedTemplate(this.dirtyText)
      }
    },
    dirtyText() {
      this.createTokenisedTemplate(this.dirtyText)
    },
    templateVariables: {
      handler() {
        this.onTemplateChange()
      }, deep: true
    },
    attachedFiles() {
      this.onTemplateChange()
    },
    templates() {
      if (this.templateText) { //we can do it as two-way bounded if will be needed
        this.templateToSend = (this.templates.find(x=>x.text && this.isTextEqual(x.text, this.templateText)) || {}).name
      }
    },
  },
  mounted() {
    // if (this.templateVariablesArray && this.templateVariablesArray.length > 0) {
    //   this.templateVariables = this.templateVariablesArray[0]
    // }
    this.init();
    this.dirtyText = this.templateText?.toString()
    this.initAttachment()
  },
  computed: {
    ...mapGetters({
      templatesList: 'wa_templates/getTemplates',
      templateUiFlags: 'wa_templates/getUIFlags',
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
    }),
    templates() {
      return this.templatesList(this.inboxId)
    },
    currentTemplate() {
      return this.templates.find(x=>x.name === this.templateToSend) || {}
    },
    hasAttachments() {
      return this.attachedFiles.length;
    },
    message() {
      return this.getMessage(this.templateVariables)
    },
    allowedAttachments() {
      return this.useTemplateSelection
        ? this.currentTemplate.allowedAttachment
        : 'image/png, image/jpeg, image/gif, image/bmp, image/tiff, application/pdf, audio/mpeg, video/mp4, audio/ogg, text/csv'
    },
    withBodyVariables() {
      return this.tokenisedTemplate.filter(x => x.type === 'parameter').length > 0
    },
    withWebsiteVariable() {
      return this.currentTemplate?.callToActionWebsiteType === 2
    },
    withWebsiteVariable2() {
      return this.currentTemplate?.callToActionWebsiteType === 2
    },
  },
  methods: {
    error() {
      const textLength = this.currentTemplate.text ? this.currentTemplate.text.replace(/{{([^}]+)}}/g, '').length : 0
      if (textLength > waMaxSizeText) return this.textSizeError(textLength);

      if (this.templateVariablesArray) {
        if (this.useTemplateSelection) {
          if (this.currentTemplate.allowedAttachment && !this.attachedFiles.length) {
            return this.$t('CONVERSATION.REPLYBOX.FILE_IS_REQUIRED');
          }
        }

        const paramsValues = Object.values(this.templateVariables);
        const filteredValues = paramsValues.filter((item) => item !== undefined);
        const mergedText = filteredValues.join('');
        const lengthAll = mergedText.length + textLength;
        if (lengthAll > waMaxSizeText) return this.textSizeError(lengthAll);

        if (paramsValues.length && !paramsValues.every(x => x && x.toString().trim())) {
          return this.$t('CONVERSATION.REPLYBOX.PARAMS_ARE_REQUIRED');
        }
      }

      return undefined
    },
    textSizeError(now) {
      return this.$t('WHATSAPP_TEMPLATES.PARSER.TEXT_SIZE_ERROR_MESSAGE', { waMaxSizeText, now });
    },
    clearUserSelection() {
      this.templateToSend = ''
      this.attachedFiles = [];
    },
    init() {
      this.dirtyText = ''
      if (this.useTemplateSelection) {
        this.$store.dispatch('wa_templates/get', this.inboxId);
      }
    },
    initAttachment() {
      if (this.fileUrl) {
        this.attachedFiles = [this.urlToAttachment(this.fileUrl)]
      }
    },
    getMessage(params) {
      return this.tokenisedTemplate.reduce((a,x)=>
        a + (x.type === 'parameter' ? (params[x.value] || '???') : x.text), '');
    },
    onTemplateChange() {
      const templ = this.currentTemplate;

      const [attachment] = this.attachedFiles;

      if (!this.templateVariablesArray) {
        const messagePayload = {
          conversationId: this.conversationId,
          message: this.message,
          contentAttributes: {
            template_info: {
              ...templ,
              parameters: Object.entries(this.templateVariables).map(x => {
                return {key: x[0], value: x[1]}
              }),
              websiteVariable: (this.withWebsiteVariable || undefined) && (this.websiteVariable || ''),
              websiteVariable2: (this.withWebsiteVariable2 || undefined) && (this.websiteVariable2 || '')
            }
          }
        }

        if (attachment && attachment.resource.file) {
          messagePayload.file = attachment.resource.file;
        }

        if (attachment && attachment.blobSignedId) {
          messagePayload.file = attachment.blobSignedId;
        }

        this.onChange(messagePayload)
      } else {
        const messagePayloadArray = this.templateVariablesArray.map(x => {
          return {
            content: this.getMessage(x),
            content_attributes: this.useTemplateSelection ? {
              template_info: {
                ...
                  templ,
                parameters:
                  Object.entries(this.templateVariables).map(y => {
                    return {key: y[0], value: x[y[0]]}
                  }),
                websiteVariable: (this.withWebsiteVariable || undefined) && (x.websiteVariable || ''),
                websiteVariable2: (this.withWebsiteVariable2 || undefined) && (x.websiteVariable2 || '')
              }
            } : {}
          }
        })

        const file = attachment && (attachment.resource.file || attachment.blobSignedId)
        this.onChange({
          templateInfo: templ,
          message: templ.text ? templ.text : this.dirtyText,
          file: file || (!attachment && this.fileUrl ? null : undefined), //null - remove
          messagePayloadArray,
          options: {withWebsiteVariable: this.withWebsiteVariable, withWebsiteVariable2: this.withWebsiteVariable2}
        })
      }
    },
    createTokenisedTemplate(text) {
      this.tokenisedTemplate = this.lexer.tokenize(text)
      //if (!this.templateVariablesArray) {
        this.templateVariables = this.tokenisedTemplate.filter(x => x.type === 'parameter').reduce((a, x) => {
          a[x.value] =
            this.templateVariablesArray && this.templateVariablesArray.length > 0 ? this.templateVariablesArray[0][x.value] : ''
          return a
        }, {})
      //}
    },
    onFileUpload(file) {
      if (true) {
        this.onDirectFileUpload(file);
      } else {
        this.onIndirectFileUpload(file);
      }
    },
    onDirectFileUpload(file) {
      if (!file) {
        return;
      }

      const MAXIMUM_FILE_UPLOAD_SIZE = allowedSize(file);
      if (checkFileSizeLimit(file, MAXIMUM_FILE_UPLOAD_SIZE)) {
        const upload = new DirectUpload(
          file.file,
          `/api/v1/accounts/${this.accountId}/direct_uploads`,
          {
            directUploadWillCreateBlobWithXHR: xhr => {
              xhr.setRequestHeader(
                'api_access_token',
                this.currentUser.access_token
              );
            },

            directUploadWillStoreFileWithXHR: xhr => {
              xhr.upload.addEventListener("progress",
                event => {
                  this.fileUploadProgresses = [{id: 1, name: file.filename || file.name, value: event.loaded / event.total}]
                });

            }
          }
        );

        upload.create((error, blob) => {
          this.fileUploadProgresses = []
          if (error) {
            this.showAlert(error);
          } else {
            this.attachFile({ file, blob });
          }
        });
      } else {
        this.showAlert(
          this.$t('CONVERSATION.FILE_SIZE_LIMIT', {
            MAXIMUM_FILE_UPLOAD_SIZE,
          })
        );
      }
    },
    onIndirectFileUpload(file) {
      if (!file) {
        return;
      }
      const MAXIMUM_FILE_UPLOAD_SIZE = allowedSize(file);
      if (checkFileSizeLimit(file, MAXIMUM_FILE_UPLOAD_SIZE)) {
        this.attachFile({ file });
      } else {
        this.showAlert(
          this.$t('CONVERSATION.FILE_SIZE_LIMIT', {
            MAXIMUM_FILE_UPLOAD_SIZE,
          })
        );
      }
    },
    attachFile({ blob, file }) {
      this.attachedFiles = []; //d99d

      const reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onloadend = () => {
        this.attachedFiles.push({
          resource: blob || file,
          thumb: reader.result,
          blobSignedId: blob ? blob.signed_id : undefined,
        });
      };
    },
    removeAttachment(itemIndex) {
      this.attachedFiles = this.attachedFiles.filter(
        (item, index) => itemIndex !== index
      );
    },
    urlToAttachment(x) {
      return (typeof x === 'string') ?
        {
          resource: {
            file: undefined,
            name: decodeURI(x.substr(x.lastIndexOf('/') + 1)),
            type: ['jpg','jpeg','gif','bmp','png','tiff'].includes(x.substr(x.lastIndexOf('.') + 1))
              ? ['image'] : [],
            size:null
          },
          thumb: x,
          url: x
        } : x
    },
    isTextEqual(text1, text2) {
      return text1.replace(/[\r\n]/g, '') === text2.replaceAll(/[\r\n]/g, '')
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.template-input {
  display: inline;
  padding: 4px;
  width: 7ch;
  min-width: 7ch;
  margin: 0 0 2px;
}

::v-deep .file-uploads {
  label {
    cursor: pointer;
  }
  &:hover .button {
    background: var(--s-100);
  }
}

::v-deep .attachment-preview-box {
  padding: 0 var(--space-normal);
  background: transparent;

  .preview-item {
    margin-top: 0;
  }
}

.text-block {
  white-space: pre;
}

textarea {
  min-height: 8rem;
}

.resize-none {
  resize: none;
}

.example-wrapper {
  display: flex;
  margin-inline-start: var(--space-larger);
  margin-bottom: var(--space-normal)
}
.attachment-wrapper {
  display: flex;
  align-items: end;
  margin-bottom: var(--space-normal);
  margin-inline-start: var(--space-larger);
  margin-top: 0
}

.website-input--wrapper {
  font-size: $font-size-small;
  margin-top: var(--space-one);
  gap: var(--space-smaller);
  display: flex;
  align-items: center;
}

</style>
