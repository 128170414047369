<template>
  <div class="profile--settings--row text-black-900 dark:text-slate-300 row">
    <div class="w-[25%] py-4 pr-6 ml-0">
      <h4 class="block-title text-black-900 dark:text-slate-200">
        {{ $t('PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE_SECTION.TITLE') }}
      </h4>
      <p>{{ $t('PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE_SECTION.NOTE') }}</p>
    </div>
    <div v-if="inboxes" class="w-[45%] flex">
      <transition-group
        name="wizard-items"
        tag="div"
        class="wizard-box small-3 columns w-full"
      >
        <div
          v-for="item in inboxes"
          :key="item.id"
          class="item"
          :class="{ active: isActive(item) }"
          @click="makeActive(item.id)"
        >
          <div class="flex items-center">
            <h3
              class="overflow-hidden whitespace-nowrap text-ellipsis leading-tight"
            >
              {{ item.name }}
            </h3>
          </div>
          <p>{{ item.body }}</p>
        </div>
      </transition-group>
      <div class="wizard-body w-[65%] max-w-[65%] !h-auto">
        <div class="w-full flex-wrap flex">
          <div class="flex self-start w-full">
            <resizable-text-area
              id="message-signature-input"
              :value="activeSignature.body"
              rows="4"
              type="text"
              :placeholder="
                $t('PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE.PLACEHOLDER')
              "
              @input="activeSignature = { body: $event }"
            />
          </div>
          <div v-if="isAnEmailChannel" class="flex self-start w-full">
            <woot-avatar-uploader
              :label="$t('PROFILE_SETTINGS.FORM.UPLOAD_IMAGE')"
              :src="activeSignature.attachment_url"
              @change="handleImageUpload"
            />
            <div class="avatar-delete-btn">
              <woot-button
                type="button"
                color-scheme="alert"
                variant="hollow"
                size="small"
                @click="deleteAttachment"
              >
                {{
                  $t(
                    'PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE_SECTION.DELETE_IMAGE'
                  )
                }}
              </woot-button>
            </div>
          </div>
          <div
            class="flex flex-row justify-end gap-2 py-2 px-0 w-full self-end"
          >
            <woot-button @click="saveSignatures">
              {{ $t('PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE_SECTION.SAVE') }}
            </woot-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import alertMixin from 'shared/mixins/alertMixin';
import ResizableTextArea from 'shared/components/ResizableTextArea';
import hasPermissionMixin from 'dashboard/mixins/hasPermission';
import { required } from 'vuelidate/lib/validators';
import inboxMixin from '../../../../../shared/mixins/inboxMixin';

export default {
  components: {
    ResizableTextArea, // d99d
  },
  mixins: [alertMixin, hasPermissionMixin, inboxMixin],
  data() {
    return {
      isUpdating: false,
      errorMessage: '',
      activeInboxId: null,
      removeAttachment: false,
    };
  },
  validations: {
    activeSignature: {
      body: {
        required,
      },
      attachment: {
        // fileLimitMb(value) {
        //   return value;
        // },
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
      currentUserId: 'getCurrentUserID',
      getAccount: 'accounts/getAccount',
      accountId: 'getCurrentAccountId',
      inboxes: 'inboxes/getInboxes',
      messageSignatures: 'getMessageSignatures',
    }),
    inbox() {
      return this.activeInbox || {};
    },
    account() {
      return this.getAccount(this.accountId);
    },
    newRulesSystemEnabled() {
      return this.account?.features?.new_rules_system;
    },
    activeInbox() {
      if (this.activeInboxId) {
        return this.inboxes.find(({ id }) => id === this.activeInboxId);
      }
      return this.inboxes[0];
    },
    activeSignature: {
      get() {
        const signature = this.messageSignatures.find(
          ({ inbox_id }) => inbox_id === this.activeInbox?.id
        ) || { inbox_id: this.activeInbox?.id, body: '' };
        return signature;
      },
      set(value) {
        this.$store.commit('UPDATE_MESSAGE_SIGNATURE', {
          ...value,
          inbox_id: this.activeInbox?.id,
          id: this.activeSignature.id,
        });
      },
    },
  },
  methods: {
    handleImageUpload({ file, url }) {
      this.activeSignature = { attachment: file, attachment_url: url };
    },
    deleteAttachment() {
      this.activeSignature = { attachment: '', attachment_url: '' };
      this.removeAttachment = true;
    },
    isActive(item) {
      return item.id === this.activeInbox?.id;
    },
    makeActive(id) {
      this.activeInboxId = id;
      this.removeAttachment = false;
    },
    async saveSignatures() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.showAlert(this.$t('PROFILE_SETTINGS.FORM.ERROR'));
        return;
      }
      const messageSignatures = [this.activeSignature];
      const isTemporaryId = this.activeSignature?.id
        ?.toString()
        ?.startsWith('temp_id');
      if (isTemporaryId) {
        delete messageSignatures[0].id;
      }
      if (this.removeAttachment && !isTemporaryId) {
        messageSignatures.unshift({ ...this.activeSignature, _destroy: true });
        delete messageSignatures[1].id;
      }
      try {
        await this.$store.dispatch('updateProfile', {
          messageSignatures: messageSignatures,
        });
        this.errorMessage = this.$t(
          'PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE_SECTION.API_SUCCESS'
        );
      } catch (error) {
        this.errorMessage = this.$t(
          'PROFILE_SETTINGS.FORM.MESSAGE_SIGNATURE_SECTION.API_ERROR'
        );
        if (error?.response?.data?.message) {
          this.errorMessage = error.response.data.message;
        }
      } finally {
        this.isUpdating = false;
        this.showAlert(this.errorMessage);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.message-editor {
  @apply px-3 mb-4;

  ::v-deep {
    .ProseMirror-menubar {
      @apply left-2;
    }
  }
}
</style>
